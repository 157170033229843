<template>
  <div class="store  my-5 py-5">
    <!-- <main-jumbotron /> -->

    <b-container>
      <h1 class="main-heading text-center my-5 py-5">
        {{ $t("heading.all_projects") }}
      </h1>

      <showcase :items="items" class="showcase" full-view />

      <subscribe class="my-5" />
    </b-container>
  </div>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";

export default {
  components: {
    Showcase: () => import("@/components/Showcase"),
    Subscribe: () => import("@/components/Subscribe")
  },

  data() {
    return {
      items: []
    };
  },

  created() {
    this.fetchProjects();
  },

  methods: {
    async fetchProjects() {
      try {
        const {
          data: { projects }
        } = await IndexData({ reqName: "projects" });
        this.items = projects;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
